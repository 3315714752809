exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktuelt-jsx": () => import("./../../../src/pages/aktuelt.jsx" /* webpackChunkName: "component---src-pages-aktuelt-jsx" */),
  "component---src-pages-ansatte-jsx": () => import("./../../../src/pages/ansatte.jsx" /* webpackChunkName: "component---src-pages-ansatte-jsx" */),
  "component---src-pages-apenhetsloven-jsx": () => import("./../../../src/pages/apenhetsloven.jsx" /* webpackChunkName: "component---src-pages-apenhetsloven-jsx" */),
  "component---src-pages-dekk-og-maskin-jsx": () => import("./../../../src/pages/dekk-og-maskin.jsx" /* webpackChunkName: "component---src-pages-dekk-og-maskin-jsx" */),
  "component---src-pages-eksport-jsx": () => import("./../../../src/pages/eksport.jsx" /* webpackChunkName: "component---src-pages-eksport-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-lofteutstyr-jsx": () => import("./../../../src/pages/lofteutstyr.jsx" /* webpackChunkName: "component---src-pages-lofteutstyr-jsx" */),
  "component---src-pages-om-oss-jsx": () => import("./../../../src/pages/om-oss.jsx" /* webpackChunkName: "component---src-pages-om-oss-jsx" */),
  "component---src-pages-skipshandel-jsx": () => import("./../../../src/pages/skipshandel.jsx" /* webpackChunkName: "component---src-pages-skipshandel-jsx" */),
  "component---src-pages-svalbard-jsx": () => import("./../../../src/pages/svalbard.jsx" /* webpackChunkName: "component---src-pages-svalbard-jsx" */),
  "component---src-pages-teknisk-jsx": () => import("./../../../src/pages/teknisk.jsx" /* webpackChunkName: "component---src-pages-teknisk-jsx" */),
  "component---src-pages-utleie-jsx": () => import("./../../../src/pages/utleie.jsx" /* webpackChunkName: "component---src-pages-utleie-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

